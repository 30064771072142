import { getNotifiyList } from '@/api/message'

const state = {

  preMessage: [],
  replayInstance: null,
  chatConnection: null,
  notify: {},
  notifyConfig: {},
  catchEvent: [],
  unReadNotfiy: undefined,
  activeRecord: undefined,
  newMessage: undefined,
  sendStopAutoMessage:undefined,
  visitorWsConnected:false// 标识访客端ws已经链接成功
}

const mutations = {
  CHANGE_VISITOR_WS_CONNECTED(state, value) {
    state.visitorWsConnected = value
  },
  CHANGE_SEND_STOP_AUTO_MESSAGE(state, value) {
    state.sendStopAutoMessage = value
  },
  CHANGE_NEWMESSAGE(state, value) {
    state.newMessage = value
  },
  CHANGE_RECORD_ID(state, value) {
    state.activeRecord = value
  },


  CHANGE_UN_READ_NOTIFY(state, value) {
    state.unReadNotfiy = value
  },
  CHNAGE_CACHE_EVENT(state, value) {
    state.catchEvent = value
  },
  CHNAGE_NOTIFY_CONFIG(state, value) {
    state.notifyConfig = value
  },
  CHNAGE_NOTIFY(state, value) {
    state.notify = value
  },
  CHNAGE_CHAT_CONNECTION(state, value) {
    state.chatConnection = value
  },
  CHANGE_PRE_MESSAGE(state, value) {
    state.preMessage = value
  },
  CHANGE_REPLAY_INSTANCE(state, value) {
    state.replayInstance = value
  },

}

const actions = {

  getNotifyCount(context) {
    return getNotifiyList({
      NotificationType: 2,
      Read: false,
      Sorting: 'CreationTime desc',
      MaxResultCount: 1
    }).then(res => {
      let temp = res.items && res.items[0] || {}
      temp.count = res.totalCount || undefined

      // context.commit('CHANGE_HOME_CHAT', temp)
      context.commit('homeInfo/CHANGE_HOME_CHAT', temp,{root:true})
      context.commit('CHANGE_UN_READ_NOTIFY', res.unReadCount)
    })

  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

